<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <el-form-item label="姓名:">
            <el-input type="text" v-model="selectForm.Name"></el-input>
          </el-form-item>
          <el-form-item label="合同类型:">
            <!-- <el-input type="text" v-model="selectForm.Tyep"></el-input> -->
            <el-select v-model="selectForm.Tyep" placeholder="请选择合同类型" style="width: 100%">
              <el-option label="聘用协议" value="聘用协议"></el-option>
              <el-option label="见习协议" value="见习协议"></el-option>
              <el-option label="非全日制用工合同" value="非全日制用工合同"></el-option>
              <el-option label="劳动合同" value="劳动合同"></el-option>
              <el-option label="承揽协议" value="承揽协议"></el-option>
              <el-option label="劳务雇佣合同" value="劳务雇佣合同"></el-option>
              <el-option label="培训协议" value="培训协议"></el-option>
              <el-option label="外包劳务雇佣合同" value="外包劳务雇佣合同"></el-option>
              <el-option label="外包劳动合同" value="外包劳动合同"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="归属公司:">
            <el-select v-model="selectForm.AttributionUnit" placeholder="请选择归属公司" style="width: 100%">
              <el-option label="格瑞德集团"  value="格瑞德集团"></el-option>
              <el-option label="山东航安新材料科技有限公司" value="山东航安新材料科技有限公司"></el-option>
              <el-option label="山东格瑞德集团进出口有限公司" value="山东格瑞德集团进出口有限公司"></el-option>
              <el-option label="山东格瑞德环保科技有限公司" value="山东格瑞德环保科技有限公司"></el-option>
              <el-option label="山东酷菱能源科技有限责任公司" value="山东酷菱能源科技有限责任公司"></el-option>
              <el-option label="山东格瑞德人工环境产业设计研究院有限公司" value="山东格瑞德人工环境产业设计研究院有限公司"></el-option>
              <el-option label="山东格物智联信息技术有限公司" value="山东格物智联信息技术有限公司"></el-option>
              <el-option label="冷却塔公司" value="冷却塔公司"></el-option>
              <el-option label="淄博格瑞德炭净环保有限公司" value="淄博格瑞德炭净环保有限公司"></el-option>
              <el-option label="山东欣瑾德贸易有限公司" value="山东欣瑾德贸易有限公司"></el-option>
              <el-option label="山东格瑞德环保材料科技有限公司" value="山东格瑞德环保材料科技有限公司"></el-option>
              <el-option label="山东德力特电机有限公司" value="山东德力特电机有限公司"></el-option>
              <el-option label="德道企服（山东）环境科技有限公司" value="德道企服（山东）环境科技有限公司"></el-option>
              <el-option label="格瑞德北京分公司" value="格瑞德北京分公司"></el-option>
              <el-option label="山东德仁四方人才发展集团有限公司" value="山东德仁四方人才发展集团有限公司"></el-option>
              <el-option label="安徽中科智服人力资源有限公司" value="安徽中科智服人力资源有限公司"></el-option>
              <el-option label="山东格瑞德环境科技有限公司" value="山东格瑞德环境科技有限公司"></el-option>
              <el-option label="山东外海人才服务有限公司青岛分公司" value="山东外海人才服务有限公司青岛分公司"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="是否到期:">
              <el-select v-model="selectForm.pastDue" placeholder="请选择" style="width: 100%">
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="0"></el-option>
              </el-select>
          </el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
        <el-table :data="users"
                  highlight-current-row
                  height="100%"
                  @current-change="selectCurrentRow"
                  @row-dblclick="handleShow"
                  class="new_table">
            <el-table-column prop="UserName" label="姓名"> </el-table-column>
            <el-table-column label="合同类型" prop="ConTypeName"></el-table-column>
            <el-table-column label="归属公司" prop="AttributionUnit"></el-table-column>

            <el-table-column label="合同开始时间"
                             prop="StartDate"
                             :formatter="formatStartTime"></el-table-column>
            <el-table-column label="合同结束时间"
                             prop="EndDate"
                             :formatter="formatStartTime"></el-table-column>
            <el-table-column label="合同期限" prop="ConDate"></el-table-column>
            <el-table-column label="类型" prop="type"></el-table-column>
            <el-table-column label="是否到期" prop="pastDue" filter-placement="bottom-end">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.pastDue === true ? 'info':'success'"
                            disable-transitions>{{scope.row.pastDue? `是`:`否`}}</el-tag>
                </template>
            </el-table-column>
        </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="optType"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form ref="SakaryForm" :model="SakaryForm" class="demo-form-inline" label-width="100px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="姓名:" prop="UserName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" :disabled="true" v-model="SakaryForm.UserName">
                <el-button slot="append" icon="el-icon-search" @click="chooseUser" v-if="operation"></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属类型:" prop="ConTypeName" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm.ConTypeName" placeholder="合同类型" @change="CertTypeModel($event)" style="width: 100%" :disabled="optDisabled">
                <el-option v-for="item in CertTypeLists" :key="item.Id" :label="item.Name" :value="item.Name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
            <el-row>
        <el-col :span="12">
            <el-form-item label="归属公司:" prop="AttributionUnit">
                <el-select v-model="SakaryForm.AttributionUnit" placeholder="归属公司" style="width: 100%" :disabled="optDisabled">
                    <el-option label="格瑞德集团"  value="格瑞德集团"></el-option>
                    <el-option label="山东航安新材料科技有限公司" value="山东航安新材料科技有限公司"></el-option>
                    <el-option label="山东格瑞德集团进出口有限公司" value="山东格瑞德集团进出口有限公司"></el-option>
                    <el-option label="山东格瑞德环保科技有限公司" value="山东格瑞德环保科技有限公司"></el-option>
                    <el-option label="山东酷菱能源科技有限责任公司" value="山东酷菱能源科技有限责任公司"></el-option>
                    <el-option label="山东格瑞德人工环境产业设计研究院有限公司" value="山东格瑞德人工环境产业设计研究院有限公司"></el-option>
                    <el-option label="山东格物智联信息技术有限公司" value="山东格物智联信息技术有限公司"></el-option>
                    <el-option label="冷却塔公司" value="冷却塔公司"></el-option>
                    <el-option label="淄博格瑞德炭净环保有限公司" value="淄博格瑞德炭净环保有限公司"></el-option>
                    <el-option label="山东欣瑾德贸易有限公司" value="山东欣瑾德贸易有限公司"></el-option>
                    <el-option label="山东格瑞德环保材料科技有限公司" value="山东格瑞德环保材料科技有限公司"></el-option>
                    <el-option label="山东德力特电机有限公司" value="山东德力特电机有限公司"></el-option>
                    <el-option label="德道企服（山东）环境科技有限公司" value="德道企服（山东）环境科技有限公司"></el-option>
                    <el-option label="格瑞德北京分公司" value="格瑞德北京分公司"></el-option>
                    <el-option label="山东德仁四方人才发展集团有限公司" value="山东德仁四方人才发展集团有限公司"></el-option>
                    <el-option label="安徽中科智服人力资源有限公司" value="安徽中科智服人力资源有限公司"></el-option>
                    <el-option label="山东格瑞德环境科技有限公司" value="山东格瑞德环境科技有限公司"></el-option>
                    <el-option label="山东外海人才服务有限公司青岛分公司" value="山东外海人才服务有限公司青岛分公司"></el-option>
                </el-select>
            </el-form-item>
          </el-col>
          </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="开始时间:" prop="StartDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]" >
              <!-- <el-date-picker type="date" v-model="SakaryForm.StartDate" value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="optDisabled" :picker-options="pickerOptions" :editable=false :default-value="effectiveDate"></el-date-picker> -->
              <el-date-picker type="date" v-model="SakaryForm.StartDate" value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="optDisabled"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结束时间:" prop="EndDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]" >
              <el-date-picker type="date" v-model="SakaryForm.EndDate" value-format="yyyy-MM-dd" placeholder="选择日期" :disabled="!operation1" :picker-options="pickerOptions" :editable=false :default-value="effectiveDate"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="合同期限:" prop="ConDate" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-input type="text" v-model="SakaryForm.ConDate" :disabled="!operation1"> </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="类型:" prop="Type" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
              <el-select v-model="SakaryForm.Type" placeholder="所属类型" style="width: 100%" :disabled="optDisabled">
                <el-option label="新签" value="新签"></el-option>
                <el-option label="续签" value="续签"></el-option>
                <el-option label="解除" value="解除"></el-option>
                <el-option label="终止" value="终止"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <label for="ConText" class="el-form-item__label" style="width: 100px">合同内容:</label>
            <el-input type="textarea" v-model="SakaryForm.ConText" style="width: 80%" :disabled="!operation1">
            </el-input>
          </el-col>
        </el-row>
        <el-row style="margin-top: 22px">
          <label for="Remark" class="el-form-item__label" style="width: 100px" >备注:</label>
          <el-input type="textarea" v-model="SakaryForm.Remark" style="width: 80%" :disabled="!operation1">
          </el-input>
        </el-row>
        <upload-files :files="SakaryForm.FileArry" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles" :IsDisabled="operation1" :IsDel="operation1"></upload-files>
          <el-row>
              <el-col :span="24" >
                  <el-table :data="SakaryForm.DetailArry" style="width: 100%">
                      <el-table-column prop="optType" label="操作类型"></el-table-column>
                      <el-table-column prop="type" label="类型"></el-table-column>
                      <el-table-column prop="insertDate" label="操作日期" :formatter="formatStartTime"></el-table-column>
                      <el-table-column prop="StartDate" label="开始时间" :formatter="formatStartTime"></el-table-column>
                      <el-table-column prop="EndDate" label="结束时间" :formatter="formatStartTime"></el-table-column>
                      <el-table-column prop="ConDate" label="合同期限"></el-table-column>
                      <el-table-column prop="ConText" label="合同内容"></el-table-column>
                      <el-table-column prop="Remark" label="备注"></el-table-column>
                  </el-table>
              </el-col>
          </el-row>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
      <UserChoose :data="choosedusers" :all="true" :single="false" @callback="usercallFunction"></UserChoose>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import {
  GetContractPageList,
  GetContractDetailPageList,
  GetContractVerify,
  GetDicContractTypePageList,
  AddContract,
  UpdateContract,
        GetContractDetail,
        DeleteContractInfo,

        AddContractDetail,
        ModifContractDetail,
        TerminateContractDetail

} from "../../api/hr";
import UserChoose from "../../components/UserChoose";
import UploadFiles from "../../components/UploadFiles";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose, UploadFiles },
  data() {
    return {
      searchVal: "",
      selectForm: {
        Name: "",
        Tyep:"",
        AttributionUnit:"",
        pastDue:"",
      },
      loading: true,
      choosedusers: [],
      CertTypeLists: [],
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      key: 0,
      SakaryVisible: false,
      addUserVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      operation1: true,

      optType:"", // 什么操作 新增，修改，解除

      buttonList: [],
      SakaryForm: {
        UserName: "",
        UserId: "",
        ConTypeId: "",
        ConTypeName: "",
        EndDate: "",
        ConDate: "",
        ConText: "",
        Remark: "",
        Type: "",
        StartDate: "",
          FileArry: [],
          DetailArry: [],
      },
      SakaryRules: {
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Type: [{ required: true, message: "请选择类型", trigger: "blur" }],
      },
      pickerOptions:{
          disabledDate: time => {
              return this.selectTime(time);//调用方法
          }
      },
      effectiveDate:new Date('2000-05-24 23:06:31'), // 有效日期
    };
  },
  computed:{
      // 修改用
      optDisabled: function () {
          if (this.operation1) {
              if (this.optType == "新增")
                  return false;
              return true;
          }
          return !this.operation1
      },
  },
  methods: {
      selectTime(time) {
          return time.getTime() < this.effectiveDate.getTime()
      },
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }
      this.SakaryForm.UserId = newdata[0].Id;
      this.SakaryForm.UserName = newdata[0].UserNameClaim;
      this.addUserVisible = false;
       GetContractVerify({ userId: this.SakaryForm.UserId }).then((res) => {
           this.effectiveDate = new Date(res.data.response);
           this.SakaryForm.StartDate = null;
           this.SakaryForm.EndDate = null;
           console.log("GetContractVerify:", this.effectiveDate, res.data.response)
      });
    },
    CertTypeModel(id) {
      let obj = {};
      obj = this.CertTypeLists.find((item) => {
        //model就是上面的数据源
        console.log(item);
        return item.Name === id; //筛选出匹配数据
      });
      console.log(obj);
      this.SakaryForm.ConTypeId = obj.Id;
      this.SakaryForm.ConTypeName = obj.Name;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.Name,
        contractType:this.selectForm.Tyep,
        attributionUnit:this.selectForm.AttributionUnit,
        isExp:this.selectForm.pastDue,
      };
      console.log(para);
        GetContractDetailPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
      handleEdit() {
          this.optType = "修改";
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      console.log(row);
      GetDicContractTypePageList().then((res) => {
        this.CertTypeLists = res.data.response.data;
      });
      GetContractDetail({ id: row.Id }).then((res) => {
        console.log(res);
          this.SakaryForm = res.data.response;
          this.SakaryForm.FileArry = null;
        this.key += 1;
        this.SakaryVisible = true;
      });
      this.operation = false;
      this.operation1 = true;
      },
      // 解除
      handleTerminate() {
          this.optType = "解除";
          let row = this.currentRow;
          if (!row) {
              this.$message({
                  message: "请选择要编辑的一行数据！",
                  type: "error",
              });
              return;
          }
          console.log(row);
          GetDicContractTypePageList().then((res) => {
              this.CertTypeLists = res.data.response.data;
          });
          GetContractDetail({ id: row.Id }).then((res) => {
              console.log(res);
              this.SakaryForm = res.data.response;
              this.SakaryForm.FileArry = null;
              this.key += 1;
              this.SakaryVisible = true;
          });
          this.operation = false;
          this.operation1 = true;
      },
      // 删除
      handleDelete() {
          let row = this.currentRow;
          if (!row) {
              this.$message({
                  message: "请选择要删除的一行数据！",
                  type: "error",
              });
              return;
          }
          this.$confirm("确定要删除吗？", "提示", {}).then(() => {
              let para = {
                  Id: row.Id,
              };
              DeleteContractInfo(para).then((res) => {
                  if (res.data.success) {
                      this.$message({
                          message: "删除成功",
                          type: "success",
                          duration: 5000,
                      });
                      this.getData(false);
                  } else {
                      this.$message({
                          message: "删除失败",
                          type: "error",
                          duration: 5000,
                      });
                  }
              });
          });
      },
  handleShow() {
      this.optType = "查看";
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }
      GetContractDetail({ id: row.Id }).then((res) => {
        console.log(res);
        this.SakaryForm = res.data.response;
        this.key += 1;
        this.SakaryVisible = true;
      });
      this.operation = false;
      this.operation1 = false;
    },
      handleAdd() {
          this.optType = "新增";
      (this.SakaryForm = {
        UserName: "",
        UserId: "",
        ConTypeId: "",
        ConTypeName: "",
        EndDate: "",
        ConDate: "",
        ConText: "",
        Remark: "",
        Type: "",
        FileArry: [],
        StartDate: "",
      }),
        GetDicContractTypePageList().then((res) => {
          this.CertTypeLists = res.data.response.data;
        });
      this.key += 1;
      (this.operation = true), (this.SakaryVisible = true);
      this.operation1 = true;
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let para = Object.assign({}, this.SakaryForm);
            if (this.operation) {
              //新增
                AddContractDetail(para).then((res) => {
                    if (res.data.success) {
                        this.$message({
                            message: "添加成功",
                            type: "success",
                            duration: 5000,
                        });
                        this.SakaryVisible = false;
                        this.getData();
                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: "error",
                            duration: 5000,
                        });
                    }
                });
              //AddContract(para).then((res) => {
              //  if (res.data.success) {
              //    this.$message({
              //      message: "添加成功",
              //      type: "success",
              //      duration: 5000,
              //    });
              //    this.SakaryVisible = false;
              //    this.getData();
              //  } else {
              //    this.$message({
              //      message: res.data.msg,
              //      type: "error",
              //      duration: 5000,
              //    });
              //  }
              //});
            } else {
                //修改
                switch (this.optType) {
                    case "新增":
                        AddContractDetail(para).then((res) => {
                            if (res.data.success) {
                                this.$message({
                                    message: "修改成功",
                                    type: "success",
                                    duration: 5000,
                                });
                                this.SakaryVisible = false;
                                this.getData(false);
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                    duration: 5000,
                                });
                            }
                        });
                        break;
                    case "修改":
                        ModifContractDetail(para).then((res) => {
                            if (res.data.success) {
                                this.$message({
                                    message: "修改成功",
                                    type: "success",
                                    duration: 5000,
                                });
                                this.SakaryVisible = false;
                                this.getData(false);
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                    duration: 5000,
                                });
                            }
                        });
                        break;
                    case "解除":
                        TerminateContractDetail(para).then((res) => {
                            if (res.data.success) {
                                this.$message({
                                    message: "修改成功",
                                    type: "success",
                                    duration: 5000,
                                });
                                this.SakaryVisible = false;
                                this.getData(false);
                            } else {
                                this.$message({
                                    message: res.data.msg,
                                    type: "error",
                                    duration: 5000,
                                });
                            }
                        });
                        break;
                }
              
            }
          } else {
            this.$message({
              message: "请检查必填项",
              type: "error",
              duration: 5000,
            });
            return false;
          }
        });
      });
      },

    dealFiles(data) {
      console.log(data.backData);
      this.SakaryForm.FileArry = data.backData;
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

